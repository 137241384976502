import {
	Avatar,
	Box,
	Divider,
	Drawer,
	Hidden,
	List,
	ListSubheader,
	Typography,
	makeStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router";
import { Link } from "react-router-dom";
import Logo from "src/components/Logo";
import NavItem from "./NavItem";

const navConfig = (user) => {
	return [
		{
			items: [
				{
					title: "Limitar o expandir los vehículos a los que doy precio",
					href: "/",
					redirect: false,
				},
				{
					title: "Premiar o castigar vehículos a los que doy precio",
					href: "/",
					redirect: false,
				},
				{
					title: "Ajustes Adicionales",
					href: "/",
					redirect: false,
				},
			],
		},
	];
};

function renderNavItems({ items, ...rest }) {
	return (
		<List disablePadding>
			{items.reduce(
				(acc, item) => reduceChildRoutes({ acc, item, ...rest }),
				[],
			)}
		</List>
	);
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
	const key = item.title + depth;

	if (item.items) {
		const open = matchPath(pathname, {
			path: item.href,
			exact: false,
		});

		acc.push(
			<NavItem
				depth={depth}
				icon={item.icon}
				key={key}
				info={item.info}
				open={Boolean(open)}
				title={item.title}
				redirect={item.redirect}
			>
				{renderNavItems({
					depth: depth + 1,
					pathname,
					items: item.items,
				})}
			</NavItem>,
		);
	} else {
		acc.push(
			<NavItem
				depth={depth}
				href={item.href}
				icon={item.icon}
				key={key}
				info={item.info}
				title={item.title}
				redirect={item.redirect}
			/>,
		);
	}

	return acc;
}

const useStyles = makeStyles(() => ({
	mobileDrawer: {
		width: 256,
		zIndex: 5000,
	},
	desktopDrawer: {
		width: 256,
		top: 64,
		height: "calc(100% - 64px)",
	},
	avatar: {
		cursor: "pointer",
		width: 64,
		height: 64,
	},
}));

function NavBar({ openMobile, onMobileClose }) {
	const classes = useStyles();
	const location = useLocation();
	const { user } = useSelector((state) => state.account);

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
	}, [location.pathname]);

	const content = (
		<Box height="100%" display="flex" flexDirection="column">
			<PerfectScrollbar options={{ suppressScrollX: true }}>
				<Hidden /*lgUp*/>
					<Box p={1} display="flex" justifyContent="center">
						<Link to="/">
							<Logo />
						</Link>
					</Box>
				</Hidden>
				<Box p={2}>
					<Box display="flex" justifyContent="center">
						<Link to="#">
							<Avatar alt="User" className={classes.avatar} src={user.avatar} />
						</Link>
					</Box>
					<Box mt={2} textAlign="center">
						<Typography variant="body1" color="textSecondary">
							{`${user.firstName} ${user.lastName}`}
						</Typography>

						<Typography variant="body2" color="textSecondary">
							{user.company.name}
						</Typography>
					</Box>
				</Box>
				<Divider />
				<Box p={2}>
					{navConfig(user).map((config) => (
						<List
							key={config}
							subheader={
								<ListSubheader disableGutters disableSticky>
									{config.subheader}
								</ListSubheader>
							}
						>
							{renderNavItems({
								items: config.items,
								pathname: location.pathname,
							})}
						</List>
					))}
				</Box>
				<Divider />
			</PerfectScrollbar>
		</Box>
	);

	return (
		<>
			<Hidden /*lgUp*/>
				<Drawer
					anchor="left"
					classes={{ paper: classes.mobileDrawer }}
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
}

NavBar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

export default NavBar;
