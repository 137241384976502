import {
	Badge,
	IconButton,
	Popover,
	SvgIcon,
	Tooltip,
	Typography,
	makeStyles,
} from "@material-ui/core";
import React, { useState, useRef } from "react";
import { Settings as SettingsIcon } from "react-feather";

const useStyles = makeStyles((theme) => ({
	badge: {
		height: 10,
		width: 10,
		borderRadius: 5,
		marginTop: 10,
		marginRight: 5,
	},
	popover: {
		width: 320,
		padding: theme.spacing(2),
	},
}));

function Settings() {
	const classes = useStyles();
	const ref = useRef(null);
	const [isOpen, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Tooltip title="Configuración">
				<Badge
					color="secondary"
					variant="dot"
					classes={{ badge: classes.badge }}
					invisible={true}
				>
					<IconButton color="inherit" onClick={handleOpen} ref={ref}>
						<SvgIcon fontSize="small">
							<SettingsIcon />
						</SvgIcon>
					</IconButton>
				</Badge>
			</Tooltip>
			<Popover
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				classes={{ paper: classes.popover }}
				anchorEl={ref.current}
				onClose={handleClose}
				open={isOpen}
			>
				<Typography variant="h6" color="textPrimary">
					Configuración
				</Typography>
			</Popover>
		</>
	);
}

export default Settings;
