import axios from "axios";
import jwtDecode from "jwt-decode";
const apiUrl = process.env.REACT_APP_API_URL;

class AuthService {
	handleAuthentication() {
		const accessToken = this.getAccessToken();

		if (!accessToken) {
			return;
		}

		if (this.isValidToken(accessToken)) {
			this.setSession(accessToken);
		} else {
			this.setSession(null);
		}
	}

	login = (email, password) =>
		new Promise((resolve, reject) => {
			const body = {
				user: {
					email,
					password,
				},
			};
			axios
				.post(`${apiUrl}users/login`, body)
				.then((response) => {
					if (response.data) {
						this.setSession(response.data.accessToken);
						resolve(response.data.user);
					} else {
						reject(response.data.error);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});

	adminLogin = (values) =>
		new Promise((resolve, reject) => {
			const body = {
				user: values,
			};
			axios
				.post(`${apiUrl}/users/admin_sign_in.json`, body, {
					withCredentials: true,
				})
				.then((response) => {
					if (response.data) {
						this.setSession(response.data.accessToken);
						resolve(response.data.user);
					} else {
						reject(response.data.error);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});

	loginInWithToken = async () => {
		const login = new Promise((resolve, reject) => {
			axios
				.get(`${apiUrl}checkAuth`)
				.then((response) => {
					if (response.data) {
						resolve(response.data);
					} else {
						reject(response.data.error);
					}
				})
				.catch((error) => {
					reject(error);
				});
		});

		try {
			const res = await Promise.resolve(login);
			return res;
		} catch (e) {
			this.logout();
			return {};
		}
	};

	logout = async () => {
		this.setSession(null);
	};

	setSession = (accessToken) => {
		if (accessToken) {
			localStorage.setItem("accessToken", accessToken);
			axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
		} else {
			localStorage.removeItem("accessToken");
			delete axios.defaults.headers.common.Authorization;
		}
	};

	getAccessToken = () => localStorage.getItem("accessToken");

	isValidToken = (accessToken) => {
		if (!accessToken) {
			return false;
		}

		const decoded = jwtDecode(accessToken);
		const currentTime = Date.now();

		return decoded.exp > currentTime;
	};

	isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
