import MomentUtils from "@date-io/moment";
import {
	StylesProvider,
	ThemeProvider,
	createStyles,
	jssPreset,
	makeStyles,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createBrowserHistory } from "history";
import { create } from "jss";
import rtl from "jss-rtl";
import { SnackbarProvider } from "notistack";
import React from "react";
import { IntlProvider } from "react-intl";
import { Router } from "react-router-dom";
import Routes from "src/Routes";
import Auth from "src/components/Auth";
import ScrollReset from "src/components/ScrollReset";
import useSettings from "src/hooks/useSettings";
import { createTheme } from "src/theme";
import locale from "./utils/locale";

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
	createStyles({
		"@global": {
			"*": {
				boxSizing: "border-box",
				margin: 0,
				padding: 0,
			},
			html: {
				"-webkit-font-smoothing": "antialiased",
				"-moz-osx-font-smoothing": "grayscale",
				width: "100%",
			},
			body: {
				width: "100%",
				minHeight: "100vh",
				backgroundColor: "#f4f6f8",
			},
			"#root": {
				width: "100%",
			},
		},
	}),
);

function App() {
	useStyles();

	const { settings } = useSettings();

	return (
		<IntlProvider messages={locale} locale="es" defaultLocale="es">
			<ThemeProvider theme={createTheme(settings)}>
				<StylesProvider jss={jss}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<SnackbarProvider maxSnack={1}>
							<Router history={history}>
								<Auth>
									<ScrollReset />
									<Routes />
								</Auth>
							</Router>
						</SnackbarProvider>
					</MuiPickersUtilsProvider>
				</StylesProvider>
			</ThemeProvider>
		</IntlProvider>
	);
}

export default App;
