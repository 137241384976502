import "react-perfect-scrollbar/dist/css/styles.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "nprogress/nprogress.css";
import "src/assets/css/prism.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "src/App";
import { SettingsProvider } from "src/context/SettingsContext";
import * as serviceWorker from "src/serviceWorker";
import { configureStore } from "src/store";
import { restoreSettings } from "src/utils/settings";

const store = configureStore();
const settings = restoreSettings();

ReactDOM.render(
	<Provider store={store}>
		<SettingsProvider settings={settings}>
			<App />
		</SettingsProvider>
	</Provider>,
	document.getElementById("root"),
);

serviceWorker.unregister();
