const getCountry = () => {
	try {
		const country = window.location.host.split(".");
		const validCountries = ["pe", "cl", "co"];
		return validCountries.includes(country[country.length - 1])
			? country[country.length - 1]
			: "cl";
	} catch (error) {
		console.error('Error en getCountry:', error);
		return "cl";
	}
};

const toPrice = (value) => {
	if (!value && value !== 0) return "";
	
	try {
		const numericValue = value.toString()
			.replace(/[^0-9]/g, "")
			.replace(/[$.,]/g, "");
		return `$${numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
	} catch (error) {
		console.error('Error en toPrice:', error);
		return "";
	}
};

const toNumber = (value) => {
	if (value === undefined || value === null || value === '') {
		return '0';
	}

	try {
		const stringValue = value.toString();
		return stringValue
			.replace(/[^0-9]/g, "")
			.replace(/\./g, "")
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
	} catch (error) {
		console.error('Error en toNumber:', error);
		return '0';
	}
};

const toPercentage = (value) => {
	if (!value && value !== 0) return "";
	
	try {
		const numericValue = value.toString().replace(/[^0-9.]/g, "");
		return numericValue || "";
	} catch (error) {
		console.error('Error en toPercentage:', error);
		return "";
	}
};

const cleanNumber = (value) => {
	if (!value && value !== 0) return "";
	
	try {
		const numericValue = parseInt(value.toString().replace(/[^0-9]/g, ""), 10);
		return numericValue || "";
	} catch (error) {
		console.error('Error en cleanNumber:', error);
		return "";
	}
};

const showRange = (min, max, type) => {
	const { toPrice, toNumber } = formatter;

	if (type === "price") {
		if (min && max) {
			return `Entre ${toPrice(min)} y ${toPrice(max)}`;
		}
		if (!min && max) {
			return `Hasta ${toPrice(max)}`;
		}
		if (min && !max) {
			return `Desde ${toPrice(min)}`;
		}
	}
	if (type === "age") {
		if (min && max) {
			return `${min} - ${max}`;
		}
		if (!min && max) {
			return `0 - ${max}`;
		}
		if (min && !max) {
			return `${min} - 15`;
		}
		if (!min && !max) {
			return "0 - 15";
		}
	}
	if (type === "km") {
		if (min && max) {
			return `${toNumber(min)} - ${toNumber(max)}`;
		}
		if (!min && max) {
			return `0 - ${toNumber(max)}`;
		}
		if (min && !max) {
			return `Desde ${toNumber(min)}`;
		}
		if (!min && !max) {
			return " - ";
		}
	}
	return "";
};

const formatter = {
	getCountry,
	showRange,
	cleanNumber,
	toPercentage,
	toNumber,
	toPrice,
};

export default formatter;
