import {
	Button,
	Collapse,
	Link as LinkH,
	ListItem,
	makeStyles,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	item: {
		display: "block",
		paddingTop: 0,
		paddingBottom: 0,
	},
	itemLeaf: {
		display: "flex",
		paddingTop: 0,
		paddingBottom: 0,
	},
	button: {
		color: theme.palette.text.secondary,
		padding: "10px 8px",
		justifyContent: "flex-start",
		textTransform: "none",
		letterSpacing: 0,
		width: "100%",
	},
	buttonLeaf: {
		color: theme.palette.text.secondary,
		padding: "10px 8px",
		justifyContent: "flex-start",
		textTransform: "none",
		letterSpacing: 0,
		width: "100%",
		fontWeight: theme.typography.fontWeightRegular,
		"&.depth-0": {
			"& $title": {
				fontWeight: theme.typography.fontWeightMedium,
			},
		},
	},
	icon: {
		display: "flex",
		alignItems: "center",
		marginRight: theme.spacing(1),
	},
	title: {
		marginRight: "auto",
	},
	active: {
		color: theme.palette.secondary.main,
		"& $title": {
			fontWeight: theme.typography.fontWeightMedium,
		},
		"& $icon": {
			color: theme.palette.secondary.main,
		},
	},
	link: {
		textDecoration: "inherit",
		"&:hover": {
			textDecoration: "inherit",
		},
	},
}));

function NavItem({
	title,
	href,
	redirect,
	depth,
	children,
	icon: Icon,
	className,
	open: openProp,
	info: Info,
	...rest
}) {
	const classes = useStyles();
	const [open, setOpen] = useState(openProp);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	let paddingLeft = 8;

	if (depth > 0) {
		paddingLeft = 32 + 8 * depth;
	}

	const style = { paddingLeft };

	if (children) {
		return (
			<ListItem
				className={clsx(classes.item, className)}
				disableGutters
				key={title}
				{...rest}
			>
				<Button className={classes.button} onClick={handleToggle} style={style}>
					{Icon && <Icon className={classes.icon} size="20" />}
					<span className={classes.title}>{title}</span>
					{open ? (
						<ExpandLessIcon size="small" color="inherit" />
					) : (
						<ExpandMoreIcon size="small" color="inherit" />
					)}
				</Button>
				<Collapse in={open}>{children}</Collapse>
			</ListItem>
		);
	}

	return (
		<ListItem
			className={clsx(classes.itemLeaf, className)}
			disableGutters
			key={title}
			{...rest}
		>
			{redirect ? (
				<LinkH href={href} className={classes.link}>
					<Button
						activeclassname={classes.active}
						className={clsx(classes.buttonLeaf, `depth-${depth}`)}
						style={style}
					>
						{Icon && <Icon className={classes.icon} size="20" />}
						<span className={classes.title}>{title}</span>
						{Info && <Info className={classes.info} />}
					</Button>
				</LinkH>
			) : (
				<RouterLink to={href} className={classes.link}>
					<Button
						activeclassname={classes.active}
						className={clsx(classes.buttonLeaf, `depth-${depth}`)}
						style={style}
					>
						{Icon && <Icon className={classes.icon} size="20" />}
						<span className={classes.title}>{title}</span>
						{Info && <Info className={classes.info} />}
					</Button>
				</RouterLink>
			)}
		</ListItem>
	);
}

NavItem.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	depth: PropTypes.number.isRequired,
	href: PropTypes.string,
	icon: PropTypes.any,
	info: PropTypes.any,
	open: PropTypes.bool,
	title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
	open: false,
};

export default NavItem;
