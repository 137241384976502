import {
	AppBar,
	Box,
	Hidden,
	IconButton,
	SvgIcon,
	Toolbar,
	makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Menu as MenuIcon } from "react-feather";
import { Link } from "react-router-dom";
import Logo from "src/components/Logo";
import { THEMES } from "src/constants";
import Account from "./Account";
import Settings from "./CustomSettings";
// import Items from './Items';

const useStyles = makeStyles((theme) => ({
	root: {
		zIndex: theme.zIndex.drawer + 100,
		...(theme.name === THEMES.LIGHT
			? {
					boxShadow: "none",
					backgroundColor: theme.palette.primary.main,
				}
			: {}),
		...(theme.name === THEMES.ONE_DARK
			? {
					backgroundColor: theme.palette.background.default,
				}
			: {}),
	},
	toolbar: {
		minHeight: 64,
	},
	appBar: {
		backgroundColor: "#2e2e2e",
	},
	separator: {
		background: "#111111",
		borderRight: "1px solid #3B3B3B",
		float: "left",
		height: "42px",
		width: "1px",
		marginRight: "10px",
		marginLeft: "10px",
	},
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
	const classes = useStyles();

	return (
		<>
			<AppBar className={clsx(classes.appBar, className)} {...rest}>
				<Toolbar className={classes.toolbar}>
					{/* MENU */}
					<Hidden lgUp>
						<IconButton
							className={classes.menuButton}
							color="inherit"
							onClick={onMobileNavOpen}
						>
							<SvgIcon fontSize="small">
								<MenuIcon />
							</SvgIcon>
						</IconButton>
					</Hidden>

					{/* LOGO */}
					<Hidden mdDown>
						<Link to="/">
							<Logo />
						</Link>
					</Hidden>
					<div className={classes.separator} />
					{/* <Box
            ml={2}
            display="flex"
            justifyContent="center"
            style={{ width: '100%' }}
          >
            <Hidden mdDown>
              <Items />
            </Hidden>
          </Box> */}
					<Box ml={2} flexGrow={1} />
					<Box ml={2}>
						<Settings />
					</Box>
					<Box ml={2}>
						<Account />
					</Box>
				</Toolbar>
			</AppBar>
		</>
	);
}

TopBar.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};

export default TopBar;
