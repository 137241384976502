import {
	Avatar,
	Box,
	ButtonBase,
	Hidden,
	Menu,
	MenuItem,
	Typography,
	makeStyles,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "src/actions/accountActions";

const useStyles = makeStyles((theme) => ({
	avatar: {
		height: 32,
		width: 32,
		marginRight: theme.spacing(1),
	},
	popover: {
		width: 200,
	},
	link: {
		color: "inherit",
		textDecoration: "inherit",
		"&:hover": {
			textDecoration: "inherit",
		},
	},
}));

function Account() {
	const classes = useStyles();
	const ref = useRef(null);
	const dispatch = useDispatch();
	const account = useSelector((state) => state.account);
	const { enqueueSnackbar } = useSnackbar();
	const [isOpen, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleLogout = async () => {
		try {
			handleClose();
			dispatch(logout());
		} catch (error) {
			enqueueSnackbar("Unable to logout", {
				variant: "error",
			});
		}
	};

	return (
		<>
			<Box
				display="flex"
				alignItems="center"
				component={ButtonBase}
				onClick={handleOpen}
				ref={ref}
			>
				<Avatar
					alt="User"
					className={classes.avatar}
					src={account.user.avatar}
				/>

				<Hidden smDown>
					<Typography variant="h6" color="inherit">
						{`${account.user.firstName} ${account.user.lastName}`}
					</Typography>
				</Hidden>
				<ArrowDropDown />
			</Box>
			<Menu
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				keepMounted
				PaperProps={{ className: classes.popover }}
				getContentAnchorEl={null}
				anchorEl={ref.current}
				open={isOpen}
			>
				<MenuItem onClick={handleLogout}>Salir</MenuItem>
			</Menu>
		</>
	);
}

export default Account;
