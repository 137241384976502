import React, { lazy, Suspense, Fragment } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";
import LoadingScreen from "src/components/LoadingScreen";
import MainLayout from "src/layouts/MainLayout";

const routesConfig = [
	// {
	//   exact: true,
	//   guard: GuestGuard,
	//   path: '/admin/sign_in',
	//   component: lazy(() => import('src/views/auth/AdminLoginView'))
	// },
	{
		exact: true,
		guard: GuestGuard,
		path: "/users/sign_in",
		component: lazy(() => import("src/views/auth/LoginView")),
	},
	{
		exact: true,
		path: "/",
		layout: MainLayout,
		guard: AuthGuard,
		component: lazy(() => import("src/views/priceConfigurator")),
	},
	{
		exact: false,
		path: "/price-competitiveness",
		layout: MainLayout,
		guard: AuthGuard,
		component: lazy(() => import("src/views/SpecialView")),
	},
	{
		component: () => <Redirect to="/users/sign_in" />,
	},
];

const renderRoutes = (routes) =>
	routes ? (
		<Suspense fallback={<LoadingScreen />}>
			<Switch>
				{routes.map((route, i) => {
					const Guard = route.guard || Fragment;
					const Layout = route.layout || Fragment;
					const Component = route.component;

					return (
						<Route
							key={i}
							path={route.path}
							exact={route.exact}
							render={(props) => (
								<Guard>
									<Layout>
										{route.routes ? (
											renderRoutes(route.routes)
										) : (
											<Component {...props} />
										)}
									</Layout>
								</Guard>
							)}
						/>
					);
				})}
			</Switch>
		</Suspense>
	) : null;

function Routes() {
	return renderRoutes(routesConfig);
}

export default Routes;
