import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUserData } from "src/actions/accountActions";
import SplashScreen from "src/components/SplashScreen";
import authService from "src/services/authService";
function Auth({ children }) {
	const dispatch = useDispatch();
	const [isLoading, setLoading] = useState(true);

	useEffect(() => {
		const initAuth = async () => {
			authService.handleAuthentication();

			if (authService.isAuthenticated()) {
				const user = await authService.loginInWithToken();
				dispatch(setUserData(user));
			}
			setLoading(false);
		};

		initAuth();
	}, [dispatch]);

	if (isLoading) {
		return <SplashScreen />;
	}

	return children;
}

export default Auth;
