import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

function AuthGuard({ children }) {
	const account = useSelector((state) => state.account);

	if (!account.user) {
		return <Redirect to="/users/sign_in" />;
	}

	return children;
}

AuthGuard.propTypes = {
	children: PropTypes.any,
};

export default AuthGuard;
