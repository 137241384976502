import { produce } from "immer";
import moment from "moment";
import {
	CLEAN_ADDITIONAL_SETTING_RULES,
	CLEAN_LIMIT_EXPAND_RULES,
	CLEAN_LIST_PRICE_RULES,
	CLEAN_MARKET_PRICE_RULES,
	UPDATE_ADDITIONAL_SETTING_RULES,
	UPDATE_LIMIT_EXPAND_RULES,
	UPDATE_LIST_PRICE_RULES,
	UPDATE_MARKET_PRICE_RULES,
} from "src/actions/priceConfiguratorActions";

const initialState = {
	marketPriceRules: {
		type: "marketPrice",
		rule_name: `Usados_${moment().format("DD_MM_YYYY_hh_mm")}`,
		brands: [],
		models: [],
		min_age: "",
		max_age: "",
		min_km: "",
		max_km: "",
		rule_type: "percentage",
		region_id: "",
		intervals: [
			{
				from: "",
				to: "",
				boolean: true,
				value: "",
			},
		],
		percentage: {
			boolean: true,
			value: "",
		},
		characteristics: {
			abs: "",
			air_conditioner: "",
			alarm: "",
			central_lock: "",
			rims: "",
			checkup: "",
			condition: "",
			key_spare: "",
			steering: "",
			seat_runs: "",
			fuel_type: "",
			owners_number: "",
			collateral: "",
			transmission: "",
			traction: "",
			airbags_number: "",
			circulation_permit: "",
			doors_number: "",
			vehicle_inspection: "",
			sun_roof: "",
			vehicle_type: "",
			tapestry: "",
		},
	},
	limitExpandRules: {
		id: "",
		min_age: "",
		max_age: "",
		min_price: "",
		max_price: "",
		include: true,
		brands: [],
		kms: [],
		characteristics: {},
		mr_id: "",
		include_n: false,
		version_count: "",
		characteristics_count: "",
		characteristics_year: "",
		model_count: "",
		model_year: "",
		app: "general",
	},
	listPriceRules: {
		id: null,
		type: "listPrice",
		discount_iva: true,
		punish: true,
		rule_name: `SemiNuevos_${moment().format("DD_MM_YYYY_hh_mm")}`,
		brands: [],
		models: [],
		region_id: "",
		current_automotive_year: {
			september: "",
			october: "",
			november: "",
			december: "",
			january: "",
			february: "",
			march: "",
			april: "",
			may: "",
			june: "",
			july: "",
			august: "",
		},
		previous_automotive_year: {
			september: "",
			october: "",
			november: "",
			december: "",
			january: "",
			february: "",
		},
	},
	additionalSettingRules: {
		id: "",
		include_minimum: false,
		business_range: 0,
		minimum_margin: 0,
		price_origin: "retake",
		custom_rules_for_ler: false,
	},
};

const priceConfiguratorReducer = (state = initialState, action) => {
	switch (action.type) {
		case CLEAN_MARKET_PRICE_RULES: {
			return produce(state, (draft) => {
				draft.marketPriceRules = initialState.marketPriceRules;
			});
		}

		case UPDATE_MARKET_PRICE_RULES: {
			const values = action.payload;
			const initial = initialState.marketPriceRules;

			const final = {};
			for (const k in values) {
				final[k] = values[k];
				if (values[k] === null) {
					final[k] = initial[k];
				}
				if (k === "percentage" && Object.keys(values[k]).length === 0) {
					final[k] = initial[k];
				}
				if (k === "intervals" && values[k].length === 0) {
					final[k] = initial[k];
				}
			}
			return produce(state, (draft) => {
				draft.marketPriceRules = final;
			});
		}
		case CLEAN_LIMIT_EXPAND_RULES: {
			return produce(state, (draft) => {
				draft.limitExpandRules = initialState.limitExpandRules;
			});
		}
		case UPDATE_LIMIT_EXPAND_RULES: {
			const values = action.payload;
			const initial = initialState.limitExpandRules;

			const final = {};
			for (const k in initial) {
				final[k] = values[k];
				if (values[k] === null || values[k] === undefined) {
					final[k] = initial[k];
				}
			}
			return produce(state, (draft) => {
				draft.limitExpandRules = final;
			});
		}
		case CLEAN_LIST_PRICE_RULES: {
			return produce(state, (draft) => {
				draft.listPriceRules = initialState.listPriceRules;
			});
		}

		case UPDATE_LIST_PRICE_RULES: {
			const values = action.payload;
			const initial = initialState.listPriceRules;

			const final = {};
			for (const k in initial) {
				final[k] = values[k];
				if (values[k] === null || values[k] === undefined) {
					final[k] = initial[k];
				}
			}

			return produce(state, (draft) => {
				draft.listPriceRules = final;
			});
		}

		case CLEAN_ADDITIONAL_SETTING_RULES: {
			return produce(state, (draft) => {
				draft.additionalSettingRules = initialState.additionalSettingRules;
			});
		}
		case UPDATE_ADDITIONAL_SETTING_RULES: {
			const values = action.payload;
			const initial = initialState.additionalSettingRules;

			const final = {};
			for (const k in initial) {
				final[k] = values[k];
				if (values[k] === null || values[k] === undefined) {
					final[k] = initial[k];
				}
			}
			return produce(state, (draft) => {
				draft.additionalSettingRules = final;
			});
		}

		default: {
			return state;
		}
	}
};

export default priceConfiguratorReducer;
