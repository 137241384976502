import formatter from "src/helpers/formatter";
import messagesCl from "./cl";
import messagesCo from "./co";
import messagesPe from "./pe";

const globalMsg = {
	cl: messagesCl,
	co: messagesCo,
	pe: messagesPe,
};

export default globalMsg[formatter.getCountry()] || globalMsg.cl;
