export const CLEAN_MARKET_PRICE_RULES =
	"@price-configurator/clean-market-price-rules";
export const UPDATE_MARKET_PRICE_RULES =
	"@price-configurator/update-market-price-rules";
export const CLEAN_LIMIT_EXPAND_RULES =
	"@price-configurator/clean-limit-expand-rules";
export const UPDATE_LIMIT_EXPAND_RULES =
	"@price-configurator/update-limit-expand-rules";
export const CLEAN_LIST_PRICE_RULES =
	"@price-configurator/clean-list-price-rules";
export const UPDATE_LIST_PRICE_RULES =
	"@price-configurator/update-list-price-rules";
export const CLEAN_ADDITIONAL_SETTING_RULES =
	"@price-configurator/clean-additional-setting-rules";
export const UPDATE_ADDITIONAL_SETTING_RULES =
	"@price-configurator/update-additional-setting-rules";

export function updateMarketPriceRules(values) {
	return async (dispatch) => {
		dispatch({
			type: UPDATE_MARKET_PRICE_RULES,
			payload: values,
		});
	};
}

export function cleanMarketPriceRules() {
	return async (dispatch) => {
		dispatch({
			type: CLEAN_MARKET_PRICE_RULES,
		});
	};
}

export function updateLimitExpandRules(values) {
	return async (dispatch) => {
		dispatch({
			type: UPDATE_LIMIT_EXPAND_RULES,
			payload: values,
		});
	};
}

export function cleanLimitExpandRules() {
	return async (dispatch) => {
		dispatch({
			type: CLEAN_LIMIT_EXPAND_RULES,
		});
	};
}

export function updateListPriceRules(values) {
	return async (dispatch) => {
		dispatch({
			type: UPDATE_LIST_PRICE_RULES,
			payload: values,
		});
	};
}

export function cleanListPriceRules() {
	return async (dispatch) => {
		dispatch({
			type: CLEAN_LIST_PRICE_RULES,
		});
	};
}

export function updateAdditionalSettingRules(values) {
	return async (dispatch) => {
		dispatch({
			type: UPDATE_ADDITIONAL_SETTING_RULES,
			payload: values,
		});
	};
}

export function cleanAdditionalSettingRules() {
	return async (dispatch) => {
		dispatch({
			type: CLEAN_ADDITIONAL_SETTING_RULES,
		});
	};
}
