import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import accountReducer from "./accountReducer";
import priceConfiguratorReducer from "./priceConfiguratorReducer";

const rootReducer = combineReducers({
	form: formReducer,
	account: accountReducer,
	price: priceConfiguratorReducer,
});

export default rootReducer;
