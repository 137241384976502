import React from "react";

function Logo(props) {
	return (
		<img
			alt="Logo"
			src={process.env.PUBLIC_URL + "/static/logo.png"}
			style={{ width: "120px" }}
			{...props}
		/>
	);
}

export default Logo;
